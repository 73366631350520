<template>
	<main class="auth-main">

		<div class="absolute left-0 top-0 h-16 px-5 flex items-center">
			<router-link to="/">
				<h1>DinAnmälan</h1>
			</router-link>
		</div>

		<div class="auth">
			<div class="auth-left"></div>


			<div class="auth-right">
				<h2 class="mb-20">Registrera dig</h2>

				<form @submit.prevent="register">
					<BaseInput type="text" v-model="user.first_name" class="auth-input">
						Förnamn
					</BaseInput>

					<BaseInput type="text" v-model="user.last_name" class="auth-input">
						Efternamn
					</BaseInput>

					<BaseInput type="text" v-model="user.ssn" class="auth-input" maxlength="12">
						Personnummer (12 siffror)
					</BaseInput>

					<BaseInput type="email" v-model="user.email" class="auth-input">
						E-postadress
					</BaseInput>

					<BaseInput type="phone" v-model="user.telephone" class="auth-input" maxlength="10">
						Telefonnummer (10 siffror)
					</BaseInput>

					<BaseInput type="password" v-model="user.password" class="auth-input">
						Lösenord
					</BaseInput>

					<BaseInput type="password" v-model="user.password_confirmation" class="auth-input">
						Verifiera lösenord
					</BaseInput>

					<BaseButton type="submit" class="auth-submit">
						Registrera
					</BaseButton>

					<p class="mt-2">
						Har du redan ett konto?
						<router-link to="/login" class="underline text-primary">
							Logga in här
						</router-link>
					</p>
				</form>
			</div>
		</div>
	</main>
</template>

<script>
export default {
	name: 'Register',
	data: () => ({
		user: {
			first_name: '',
			last_name: '',
			ssn: '',
			email: '',
			telephone: '',
			password: '',
			password_confirmation: '',
		},
	}),
	methods: {
		register() {
			this.$store.dispatch('AuthStore/register', this.user)
		},
	},
}
</script>

<style scoped>
</style>